import React from "react"
import logo1 from '../assets/logo (1).png'
import logo2 from '../assets/logo (2).png'
import logo3 from '../assets/logo (3).png'
import logo4 from '../assets/logo (4).png'
import logo5 from '../assets/logo (5).png'
const LogoSection =()=>{
    return(
        <div className="w-screen  bg-gray-400">

       

<div className="flex py-4 gap-10 justify-around items-center  ">

<img src={logo1} alt="" className=""/>
<img src={logo2} alt="" className="  " />
<img src={logo3} alt="" className=" "/>
<img src={logo4} alt="" className=" "/>
<img src={logo5} alt=""className="" />
</div>
</div>
    )
}
export default LogoSection