import React from "react"
function ContactUs() {
    return (
        <div className="p-5 w-full py-[3rem]">
            <div className="text-4xl text-center">
                Lets Get in Touch
            </div>
            <div className="text-xl text-center">
                We are here to Help you!
            </div>
            <div className="w-[400px] mt-[4rem] flex flex-col items-center gap-2 m-auto">


                <div className="flex  gap-2">
                    <input

                        placeholder='First Name'
                        className="md:mt-0 mt-5 h-[40px] w-[250px] border-2 rounded-lg px-5 md:py-2"
                    />



                    <input

                        placeholder='Last Name'

                        className="md:mt-0 mt-5 h-[40px] w-[250px] border-2 rounded-lg  px-5 md:py-2"
                    />
                </div>

                <div className="flex  gap-2">
                    <input
                        type="email"
                        placeholder='Email'
                        className="md:mt-0 mt-5 h-[40px] w-[250px] border-2 rounded-lg px-5 md:py-2"
                    />



                    <input
                        placeholder='Phone Number'
                        className="md:mt-0 mt-5 h-[40px] w-[250px] border-2 rounded-lg  px-5 md:py-2"
                    />
                </div>
                <textarea
                    id="message"
                    name="message"
                    rows="5"
                    className="mt-1 block min-w-[510px] px-4 py-2 border border-gray-300 rounded-md shadow-sm  focus:border-blue-500"
                    placeholder="Your message"
                ></textarea>

<button
                       
                        className="outline outline-2 outline-mobius-golden mt-4 text-mobius-golden hover:bg-mobius-golden hover:text-white transition-colors duration-150 ease-in-out h-10  text-xl rounded-xl p-2"
                     >
                       Submit
                     </button>
            </div>

        </div>
    )
}
export default ContactUs