import React, { useState, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import '../../i18n'
import axios from "axios";
import SummaryContent from "./SummaryContent";
import PlaneDetails from "./PlaneDetails";
import PriceBreakdown from "./PriceBreakdown";
import Loading from "@/components/Loader/Loading";

const SummaryComponent = ({ flightType, flightDay, destination, passengerNumber }) => {
    const {t}=useTranslation()
    const routerLocation = useLocation();
    let navigatePage = useNavigate()
    // const transactionId= parseInt(localStorage.getItem('transactionId'), 10);
    const transactionId = parseInt(localStorage.getItem('transactionId'), 10);
    const reservationCodeR = localStorage.getItem('reservationCodeR');

    const storedFlightId = localStorage.getItem('flightId');
    let isRound = JSON.parse(localStorage.getItem('isRound'));

    const { flight, transactionIdR, reservationCode, sameAsDeparture } = routerLocation.state || {};
    const [flightTime, setFlightTime] = useState('');
    const [returnTime, setReturnTime] = useState('')
    const [flightData, setFlightData] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const [returnData, setReturnData] = useState([])

    // useEffect(() => {
    //     // console.log(transactionId,'is transaction id')
    //     // console.log(transactionIdR,'is return transaction')
    //     console.log(flight, 'is flight Id')
    //     console.log(transactionId, 'is transaction id')
    //     console.log(reservationCodeR, 'is return Rcode')
    //     console.log(reservationCode, 'is departure Rcode')

    //     // console.log(storedFlightId,'is stored flight id')
    // }, [transactionId])

    const fetchOrderSummary = async (transactionId, setFlight) => {
        try {
            // Replace with your API URL
            const response = await axios.get(`api/flights/order-summary/retrieve_order_summary/`, {
                params: {
                    transaction_id: transactionId,
                },
            });
            // Handle the response data
            const orderSummary = response.data;
            setFlight(response.data)
            console.log('Order Summary:', orderSummary);

            // You can now use this data to update your state or render it in your component
            return orderSummary;
        } catch (error) {
            // Handle any errors
            console.error('Error fetching order summary:', error.response ? error.response.data : error.message);
        }
    };
    useEffect(() => {
        if (isRound) {
            fetchOrderSummary(transactionIdR, setFlightData);
            // fetchOrderSummary(transactionIdR,setReturnData)
            // console.log(transactionId)

        }
        else {
            // console.log(transactionId)
            // console.log(transactionIdR)
            fetchOrderSummary(transactionIdR, setFlightData)
        }
    }, [setFlightData, setReturnData, transactionId, transactionIdR, isRound])

    const submitPayment = async ({ flight, transactionId, navigate }) => {
        setIsLoading(true)
        const paymentStatus = 'aprobado'
        const paymentData = {
            transaction_id: transactionId, // Single transaction ID for all passengers and flights
            payment_status: paymentStatus, // Payment status ('aprobado' or 'rechazado')
        };
        try {
            // Make the POST request to process payment for the trip
            const response = await axios.post(`api/flights/flights/${flight}/process_payment/`, paymentData);

            // Handle successful response
            if (response.status === 201) {
                console.log('Payment approved and tickets generated!');
                console.log('Transaction ID:', response.data.transaction_id); // Confirm processed transaction ID
                console.log('Generated Tickets:', response.data.tickets); // List of generated tickets
                if (navigate) {
                    setIsLoading(false)
                    navigatePage('/payment-confirmation', { state: { sameAsDeparture, reservationCode, isRound } })
                }

            } else {
                console.error('Payment processing failed:', response.data);
            }
        } catch (error) {
            console.error('Error processing payment:', error.response ? error.response.data : error.message);
        }
    }

    const handlePayment = () => {


        if (isRound) {
            if (sameAsDeparture) {

                submitPayment({ flight: flight, transactionId: transactionIdR, navigate: true })

            }
            else {
                submitPayment({ flight: flight, transactionId: transactionId, navigate: false })
                submitPayment({ flight: storedFlightId, transactionId: transactionIdR, navigate: true })


            }



        }
        else {
            submitPayment({ flight: flight, transactionId: transactionId, navigate: true })
        }

    }
    const calculateTime = (departure, arrival, setTime) => {
        if (departure && arrival) {
            const [hours1, minutes1] = departure.split(":").map(Number);
            const [hours2, minutes2] = arrival.split(":").map(Number);

            const totalMinutes1 = hours1 * 60 + minutes1;
            const totalMinutes2 = hours2 * 60 + minutes2;

            const differenceInMinutes = Math.abs(totalMinutes1 - totalMinutes2);

            const diffHours = Math.floor(differenceInMinutes / 60);
            const diffMinutes = differenceInMinutes % 60;

            const calculatedTime = `${diffHours}:${diffMinutes < 10 ? '0' : ''}${diffMinutes}`;
            setTime(calculatedTime)

        }


    }

    useEffect(() => {
        if (flightData?.flights && flightData.flights[0]) {




            if (isRound) {
                if(sameAsDeparture){
            
                    const departure = flightData?.flights[1]?.flight?.departure_time.slice(0, 5)
                    const arrival = flightData?.flights[1]?.flight?.arrival_time.slice(0, 5)
                    const R_departure = flightData?.flights[0]?.flight?.departure_time.slice(0, 5)
                    const R_arrival = flightData?.flights[0]?.flight?.arrival_time.slice(0, 5)
    
                    calculateTime(departure, arrival, setFlightTime)
                    calculateTime(R_departure, R_arrival, setReturnTime)
                }
                else{
                    
                const departure = flightData?.flights[0]?.flight?.departure_time.slice(0, 5)
                const arrival = flightData?.flights[0]?.flight?.arrival_time.slice(0, 5)
                const R_departure = flightData?.flights[1]?.flight?.departure_time.slice(0, 5)
                const R_arrival = flightData?.flights[1]?.flight?.arrival_time.slice(0, 5)

                calculateTime(departure, arrival, setFlightTime)
                calculateTime(R_departure, R_arrival, setReturnTime)
                }


            }
            else {

                const departure = flightData?.flights[0]?.flight?.departure_time.slice(0, 5)
                const arrival = flightData?.flights[0]?.flight?.arrival_time.slice(0, 5)


                calculateTime(departure, arrival, setFlightTime)


            }

        }


    }, [setFlightTime, flightData, setReturnTime, isRound])
    useEffect(() => {
        console.log(flightData, 'is flight data')
        // console.log(returnData)
        // console.log(isRound,'is isRound')
        // console.log(flightTime,'is flight time')
    })

    return (
        <div >
            {isLoading ? (
                <Loading />
            ) : (

                <div>


                    {isRound ? (
                        <div className="flex flex-col md:flex-row justify-evenly md:items-start items-center">
                            {sameAsDeparture ?
                                (
                                    <div className="flex flex-col md:flex-row justify-evenly md:items-start items-center">
                                        <div className="">



                                            <div className="border-4 flex flex-col   md:gap-[1.2rem] gap-[4rem] border-mobius-golden m-6 py-4 rounded-[20px]  px-16  ">
                                                {flightData?.flights && flightData.flights[1] && (
                                                    <SummaryContent flightType={t('orderSummary.departureFlight')}
                                                        flightDay={flightData?.flights[1]?.flight.departure_date}
                                                        flight_time={flightTime}
                                                        origin={flightData?.flights[1]?.flight?.origin_details.iata_code}
                                                        departure_time={flightData?.flights[1]?.flight?.departure_time.slice(0, 5)}
                                                        Airline={flightData?.flights[1]?.flight?.airline}
                                                        destination={flightData?.flights[1]?.flight?.destination_details.iata_code}
                                                        arrival_time={flightData?.flights[1]?.flight?.arrival_time.slice(0, 5)}
                                                    />
                                                )}
                                                {flightData?.flights && flightData.flights[0] && (
                                                    <SummaryContent flightType={t('orderSummary.returnFlight')}
                                                        flightDay={flightData?.flights[0]?.flight.departure_date}
                                                        flight_time={returnTime}
                                                        origin={flightData?.flights[0]?.flight?.origin_details.iata_code}
                                                        departure_time={flightData?.flights[0]?.flight?.departure_time.slice(0, 5)}
                                                        Airline={flightData?.flights[0]?.flight?.airline}
                                                        destination={flightData?.flights[0]?.flight?.destination_details.iata_code}
                                                        arrival_time={flightData?.flights[0]?.flight?.arrival_time.slice(0, 5)}
                                                    />
                                                )}
                                            </div>

                                            <div className="flex flex-col gap-12 py-6 border-y-2 mt-[10rem] border-mobius-golden">
                                                {flightData?.flights && flightData.flights[1] && (
                                                    <PlaneDetails planeType={t('orderSummary.departurePlane')}
                                                        planeModel={flightData?.flights[1]?.plane?.planeModel}
                                                        crewData={flightData?.flights[1]?.crew}
                                                    />)}
                                                {flightData?.flights && flightData.flights[1] && (
                                                    <PlaneDetails planeType={t('orderSummary.returnPlane')}
                                                        planeModel={flightData?.flights[0]?.plane?.planeModel}
                                                        crewData={flightData?.flights[0]?.crew}
                                                    />)}
                                            </div>

                                        </div>
                                        {flightData?.flights && flightData.flights[0] && (
                                            <PriceBreakdown
                                                isRound={isRound}
                                                origin={flightData?.flights[1]?.flight?.origin_details.iata_code}
                                                destination={flightData?.flights[1]?.flight?.destination_details.iata_code}
                                                price={flightData.total_amount}
                                                processPayment={handlePayment}

                                            />
                                        )}

                                    </div>
                                ) : (
                                    <div className="flex flex-col md:flex-row justify-evenly md:items-start items-center">
                                        <div className="">



                                            <div className="border-4 flex flex-col   md:gap-[1.2rem] gap-[4rem] border-mobius-golden m-6 py-4 rounded-[20px]  px-16  ">
                                                {flightData?.flights && flightData.flights[0] && (
                                                    <SummaryContent flightType={t('orderSummary.departureFlight')}
                                                        flightDay={flightData?.flights[0]?.flight.departure_date}
                                                        flight_time={flightTime}
                                                        origin={flightData?.flights[0]?.flight?.origin_details.iata_code}
                                                        departure_time={flightData?.flights[0]?.flight?.departure_time.slice(0, 5)}
                                                        Airline={flightData?.flights[0]?.flight?.airline}
                                                        destination={flightData?.flights[0]?.flight?.destination_details.iata_code}
                                                        arrival_time={flightData?.flights[0]?.flight?.arrival_time.slice(0, 5)}
                                                    />
                                                )}
                                                {flightData?.flights && flightData.flights[1] && (
                                                    <SummaryContent flightType={t('orderSummary.returnFlight')}
                                                        flightDay={flightData?.flights[1]?.flight.departure_date}
                                                        flight_time={returnTime}
                                                        origin={flightData?.flights[1]?.flight?.origin_details.iata_code}
                                                        departure_time={flightData?.flights[1]?.flight?.departure_time.slice(0, 5)}
                                                        Airline={flightData?.flights[1]?.flight?.airline}
                                                        destination={flightData?.flights[1]?.flight?.destination_details.iata_code}
                                                        arrival_time={flightData?.flights[1]?.flight?.arrival_time.slice(0, 5)}
                                                    />
                                                )}
                                            </div>

                                            <div className="flex flex-col gap-12 py-6 border-y-2 mt-[10rem] border-mobius-golden">
                                                {flightData?.flights && flightData.flights[0] && (
                                                    <PlaneDetails planeType={t('orderSummary.departurePlane')}
                                                        planeModel={flightData?.flights[0]?.plane?.planeModel}
                                                        crewData={flightData?.flights[0]?.crew}
                                                    />)}
                                                {flightData?.flights && flightData.flights[1] && (
                                                    <PlaneDetails planeType={t('orderSummary.returnPlane')}
                                                        planeModel={flightData?.flights[1]?.plane?.planeModel}
                                                        crewData={flightData?.flights[1]?.crew}
                                                    />)}
                                            </div>

                                        </div>
                                        {flightData?.flights && flightData.flights[0] && (
                                            <PriceBreakdown
                                                isRound={isRound}
                                                origin={flightData?.flights[0]?.flight?.origin_details.iata_code}
                                                destination={flightData?.flights[0]?.flight?.destination_details.iata_code}
                                                price={flightData.total_amount}
                                                processPayment={handlePayment}

                                            />
                                        )}
                                    </div>
                                )
                            }

                        </div>
                    ) : (
                        <div className="flex justify-evenly items-start">
                            <div className="flex flex-col items-center justify-center gap-12">
                                <div className="border-4 flex flex-col   gap-[1.2rem] border-mobius-golden m-6 py-4 rounded-[20px]  px-16 ">
                                    {flightData?.flights && flightData.flights[0] && (
                                        <SummaryContent flightType={t('orderSummary.departureFlight')}
                                            flightDay={flightData?.flights[0]?.flight.departure_date}
                                            flight_time={flightTime}
                                            origin={flightData?.flights[0]?.flight?.origin_details.iata_code}
                                            departure_time={flightData?.flights[0]?.flight?.departure_time.slice(0, 5)}
                                            Airline={flightData?.flights[0]?.flight?.airline}
                                            destination={flightData?.flights[0]?.flight?.destination_details.iata_code}
                                            arrival_time={flightData?.flights[0]?.flight?.arrival_time.slice(0, 5)}
                                        />
                                    )}
                                </div>


                                <div className="flex flex-col gap-12 py-6 border-y-2 mt-[8rem] border-mobius-golden">
                                    {flightData?.flights && flightData.flights[0] && (
                                        <PlaneDetails planeType={t('orderSummary.departurePlane')}
                                            planeModel={flightData?.flights[0]?.plane?.planeModel}
                                            crewData={flightData?.flights[0]?.crew}
                                        />)}

                                </div>

                            </div>

                            {flightData?.flights && flightData.flights[0] && (
                                <PriceBreakdown
                                    isRound={isRound}
                                    origin={flightData?.flights[0]?.flight?.origin_details.iata_code}
                                    destination={flightData?.flights[0]?.flight?.destination_details.iata_code}
                                    price={flightData.total_amount}
                                    processPayment={handlePayment}

                                />
                            )}



                        </div>
                    )



                    }
                </div>
            )}

        </div>

    )
}
export default SummaryComponent